<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Modifica Modello Pratica</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="saveProcedureTemplate"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="localProcedureTemplateData.title"
                  label="Titolo"
                  outlined
                  required
                  dense
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="localProcedureTemplateData.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="saveProcedureTemplate"
                >
                  Salva
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EditProcedureTemplate',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    procedureTemplateData: Object,
  },
  data() {
    return {
      form: {},
      localProcedureTemplateData: Object,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'procedureList']),
  },
  watch: {
    isShow(newShow) {
      if (newShow) {
        this.localProcedureTemplateData = JSON.parse(JSON.stringify(this.procedureTemplateData))
      }
    },
  },
  methods: {
    async saveProcedureTemplate() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.$api.editProcedureTemplate(this.procedureTemplateData.id, this.localProcedureTemplateData)
          this.$emit('reload-data')
          await this.$store.dispatch('set_alert', { msg: 'Modello modificato con successo', type: 'success' })

          this.closeModal()
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    closeModal() {
      this.procedure_id = null
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
