<template>
    <v-chip
        class="ma-2 white--text"
        :color="counterChipClass"
    >
        <v-avatar
            left
            :color="counterAvatarClass"
        >
            {{ counter }}
        </v-avatar>
        {{ label }}
    </v-chip>
</template>

<script>
export default {
    name: "counter-chip",
    props: {
        counter: {
            type: Number,
            default: 0,
            required: true
        },
        label: {
            type: String,
            required: true
        }
    },
    computed: {
        counterChipClass() {
            let color = ""
            if(this.counter === 0){
                color = 'grey'
            }else{
                color = 'green'
            }

            return color
        },
        counterAvatarClass() {
            let color = ""
            if(this.counter === 0){
                color = 'grey darken-4'
            }else{
                color = 'green darken-4'
            }

            return color
        }
    }
}
</script>
