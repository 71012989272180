<template>
    <div class="d-flex">
        <v-btn
            class="my-3"
            :to="'/procedure-templates'"
        >
            TORNA ALLA LISTA
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="showReloadResource"
          color="primary"
          rounded
          class="my-3 mx-2 hidden-xs-only"
          :loading="loadingReloadResource"
          @click="clickReloadResource"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
        <v-btn color="primary" class="my-3" @click="clickCreateDocument">
            <v-icon>mdi-plus</v-icon>
            AGGIUNGI DOCUMENTO
        </v-btn>
    </div>
</template>

<script>

export default {
    name: "procedure-template-detail-header",
    props: {
        documentTemplateCreationEnabled: {
            type: Boolean,
            default: true
        },
        noBackButton: {
          type: Boolean,
          default: false,
        },
        showReloadResource: {
          type: Boolean,
          default: false,
        },
        loadingReloadResource: {
          type: Boolean,
          default: false,
        },
    },
    methods: {
        clickCreateDocument() {
            this.$emit('clickCreateDocument')
        },
        clickReloadResource() {
          this.$emit('clickReloadResource')
        },
    },
}
</script>
