<template>
  <v-card
    class="mt-3 d-flex flex-column"
    :style="urgencyCard(documentObj.priority)"
    height="100%"
  >
    <v-card-title>
      <v-row>
        <v-col
          cols="10"
        >
          <h5>{{ documentObj.title }}</h5>
        </v-col>
        <v-col
          class="d-flex justify-end"
          cols="2"
        >
          <document-menu
            v-if="loggedUser.role === 'professional' && !documentReadOnly"
            @editDocument="isShow.edit=true"
            @deleteDocument="isShow.delete=true"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text style="overflow-y: auto; min-height:70px">
      <v-row>
        <v-col>
          <div>
            {{ documentObj.description }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions>
      <template>
        <v-spacer></v-spacer>
        <document-template-action-menu
          v-if="!noActionMenu"
          :document-obj="documentObj"
        />
      </template>
    </v-card-actions>
    <edit-document-template
      :is-show="isShow.edit"
      :document-data="documentObj"
      @close="isShow.edit=false"
      @reload-data="reloadData"
    />
    <delete-document-template
      :is-show="isShow.delete"
      :document-data="documentObj"
      @close="isShow.delete=false"
      @reload-data="reloadData"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentMenu from '@/components/menus/document-menu.vue'
import DocumentTemplateActionMenu from '@/components/menus/document-template-action-menu.vue'
import EditDocumentTemplate from '@/components/modals/edit-document-template.vue'
import DeleteDocumentTemplate from '@/components/modals/delete-document-template.vue'

export default {
  name: 'DocumentCard',
  components: {
    EditDocumentTemplate,
    DeleteDocumentTemplate,
    DocumentTemplateActionMenu,
    DocumentMenu,
  },
  props: {
    documentObj: {
      type: Object,
      required: true,
    },
    documentReadOnly: {
      type: Boolean,
      default: false,
    },
    documentRoute: {
      type: String,
      default: 'procedureDocumentsView',
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    noAction: {
      type: Boolean,
      default: false,
    },
    noActionMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: {
        fileDownloadAll: {},
      },
      isShow: {
        upload: false,
        edit: false,
        delete: false,
        confirm: false,
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUser']),
    uploadEnabled() {
      return !(this.documentStatusClosed || this.documentStatusCompleted || this.documentStatusAccepted)
    },
    documentStatusClosed() {
      return this.documentObj.document_setting_status.is_closed
    },
    documentStatusCompleted() {
      return this.documentObj.document_setting_status.is_completed
    },
    documentStatusAccepted() {
      return this.documentObj.document_setting_status.is_accepted
    },
    uploadUrl() {
      return `${this.$apiUrl}procedure-templates/${this.documentObj.procedure_id}/documents/${this.documentObj.id}`
    },
  },
  methods: {
    urgencyCard(priority) {
      if (priority === 'high') {
        return 'border-top:10px solid #ffccbc;'
      } if (priority === 'low') {
        return 'border-top:10px solid #c8e6c9;'
      }

      return 'border-top:10px solid #c5cae9;'
    },
    goToDocumentDetail(did) {
      this.$router.push({
        name: 'procedureTemplateDocumentsView',
        params: {
          id: this.documentObj.procedure_id,
          did,
        },
      })
    },
    reloadData() {
      this.$emit('reloadData')
    },
  },
}
</script>
