import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-3 d-flex flex-column",style:(_vm.urgencyCard(_vm.documentObj.priority)),attrs:{"height":"100%"}},[_c(VCardTitle,[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c('h5',[_vm._v(_vm._s(_vm.documentObj.title))])]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[(_vm.loggedUser.role === 'professional' && !_vm.documentReadOnly)?_c('document-menu',{on:{"editDocument":function($event){_vm.isShow.edit=true},"deleteDocument":function($event){_vm.isShow.delete=true}}}):_vm._e()],1)],1)],1),_c(VCardText,{staticStyle:{"overflow-y":"auto","min-height":"70px"}},[_c(VRow,[_c(VCol,[_c('div',[_vm._v(" "+_vm._s(_vm.documentObj.description)+" ")])])],1)],1),_c(VSpacer),_c(VCardActions,[[_c(VSpacer),(!_vm.noActionMenu)?_c('document-template-action-menu',{attrs:{"document-obj":_vm.documentObj}}):_vm._e()]],2),_c('edit-document-template',{attrs:{"is-show":_vm.isShow.edit,"document-data":_vm.documentObj},on:{"close":function($event){_vm.isShow.edit=false},"reload-data":_vm.reloadData}}),_c('delete-document-template',{attrs:{"is-show":_vm.isShow.delete,"document-data":_vm.documentObj},on:{"close":function($event){_vm.isShow.delete=false},"reload-data":_vm.reloadData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }