<template>
  <div>
    <procedure-template-detail-header
      v-if="loggedUser.role === 'professional'"
      show-reload-resource
      :loading-reload-resource="loading.reloadData"
      @clickCreateDocument="isShow.doc_create=true"
      @clickReloadResource="reloadData"
    />
    <procedure-template-card
      :procedure-template-obj="procedureTemplateData"
      :procedure-enter-btn="false"
    />
    <v-row>
      <v-col
        v-for="(doc,idx) in procedureTemplateData.documents"
        :key="idx"
        cols="12"
        sm="12"
        md="4"
      >
        <document-template-card
          no-action-menu
          :document-obj="doc"
        />
      </v-col>
    </v-row>
    <create-document-template
      :is-show="isShow.doc_create"
      @close="isShow.doc_create=false"
      @reloadData="getData"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ProcedureTemplateDetailHeader from '@/components/section-headers/procedure-template-detail-header.vue'
import ProcedureTemplateCard from '@/components/cards/procedure-template-card.vue'
import DocumentTemplateCard from '@/components/cards/document-template-card.vue'

import CreateDocumentTemplate from '../../components/modals/create-document-template.vue'

export default {
  components: {
    ProcedureTemplateDetailHeader,
    ProcedureTemplateCard,
    CreateDocumentTemplate,
    DocumentTemplateCard,
  },
  data() {
    return {
      isShow: {
        doc_create: false,
        doc_edit: false,
      },
      loading: {
        reloadData: false,
      },
      procedureTemplateData: {},
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'I miei template',
          to: '/procedure-templates',
        },
        {
          text: 'Dettaglio template',
          to: `/procedure-template/${this.$route.params.id}/view`,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'procedureTemplateList']),
  },
  mounted() {
    this.getData()
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    async reloadData() {
      this.loading.reloadData = true
      setTimeout(async () => {
        try {
          await this.$store.dispatch('getProcedureTemplate', this.procedureTemplateData.id)
          this.procedureTemplateData = await this.procedureList.find(item => item.id === this.procedureTemplateData.id)
        } catch (error) {
          console.log(error)
        }
        this.loading.reloadData = false
      }, 1000)
    },
    async getData() {
      if (this.$route.params.id) {
        try {
          const procedureTemplateId = parseInt(this.$route.params.id, 10)
          this.procedureTemplateData = await this.procedureTemplateList.find(item => item.id === procedureTemplateId)
        } catch (e) {
          console.log(e)
        }
      }
    },
    goToList() {
      this.$router.push({ name: 'procedureTemplates' })
    },
    urgencyCard(priority) {
      if (priority === 'high') {
        return 'border-top:10px solid #ffccbc;'
      } if (priority === 'low') {
        return 'border-top:10px solid #c8e6c9;'
      }

      return 'border-top:10px solid #c5cae9;'
    },
  },
}
</script>

<style scoped>

</style>
