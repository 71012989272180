<template>
  <v-menu
    offset-y
    :disabled="loading"
  >
    <template v-slot:activator="{ on, attrs }">
      <template>
        <v-chip
          class="mb-2"
          :color="(documentObj.documentFiles_count > 0) ? 'green' : 'grey'"
          text-color="white"
          v-bind="attrs"
          @dblclick="goToDocumentDetail(documentObj.id)"
          v-on="on"
        >
          <v-avatar
            left
            :class="(documentObj.documentFiles_count > 0) ? 'green darken-4' : 'grey darken-4'"
          >
            <span>
              {{ documentObj.documentFiles_count }}
            </span>
          </v-avatar>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="white"
            size="20"
            width="2"
          />
          <span
            v-else
          >
            Gestisci
          </span>
        </v-chip>
      </template>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item
          link
          :to="{name:'procedureTemplateView',params:{id:documentObj.procedure_template_id,did:documentObj.id}}"
        >
          <v-list-item-title>
            <v-icon
              small
              left
            >
              mdi-folder-open-outline
            </v-icon>
            Apri
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DocumentTemplateActionMenu',
  props: {
    documentObj: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['loggedUser']),

  },
  methods: {
    goToDocumentDetail() {
      this.$router.push({ name: 'procedureTemplateView', params: { id: this.documentObj.procedure_template_id, did: this.documentObj.id } })
    },
  },
}
</script>
