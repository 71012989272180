<template>
    <div>
        <v-dialog
            v-model="isShow"
            persistent
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Elimina Documento del Modello</span>
                </v-card-title>
                <v-card-text>
                    <v-row class="ma-0">
                        <v-col
                            cols="12"
                        >
                            <p>L'eliminazione del documento è permanente, accertarsi di voler continuare.</p>
                        </v-col>
                        <v-col
                            cols="12"
                            v-if="noDelete"
                        >
                            <p>Per eliminare un documento "urgente" è necessario digitare qui sotto la parola ELIMINA</p>
                            <v-text-field
                                v-model="testWord"
                                label="Controllo"
                                outlined
                                dense
                                @input="checkTestWord"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="text-right">
                            <v-btn
                                @click="closeModal"
                                class="ma-2"
                                :disabled="loading"
                            >
                                Chiudi
                            </v-btn>
                            <v-btn
                                @click.prevent="destroyDocumentTemplate"
                                color="red"
                                dark
                                class="ma-2"
                                :disabled="noDelete"
                                :loading="loading"
                            >
                                Elimina
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>

export default {
    name: "delete-document-template",
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        documentData: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            noDelete: false,
            testWord: "",
            loading: false
        }
    },
    mounted() {
        this.checkPriority()
    },
    methods: {
        async destroyDocumentTemplate() {
            try {
                this.loading = true
                await this.$api.destroyDocumentTemplate(this.documentData.procedure_template_id, this.documentData.id, this.documentData)
                await this.$store.dispatch('getProcedureTemplate',this.documentData.procedure_template_id)
                this.$store.dispatch('set_alert', {msg: "Documento Eliminato con successo", type: 'success'})
                this.$emit('reload-data')
                this.closeModal()
            } catch (e) {
                this.loading = false
                this.$store.dispatch('set_alert', {msg: "error", type: 'error'})
            }
        },
        checkPriority() {
            if (this.documentData.priority === 'high') {
                this.noDelete = true
            }
        },
        checkTestWord() {
            if (this.testWord !== 'elimina') {
                this.noDelete = false
            }
        },
        closeModal() {
            this.$emit('close');
            this.noDelete = false
            this.testWord = ""
            this.loading = false
        }
    },
    watch: {
        dialogVisible(isShow) {
            if (isShow) {
                this.checkPriority()
            }
        }
    }
}
</script>

<style scoped>

</style>
