<template>
    <div>
        <v-dialog
            v-model="isShow"
            persistent
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Elimina Modello</span>
                </v-card-title>
                <v-card-text>
                    <v-row class="ma-0">
                        <v-col
                            cols="12"
                        >
                            <p>L'eliminazione del modello è permanente, accertarsi di voler continuare.</p>
                        </v-col>
                        <v-col cols="12" class="text-right">
                            <v-btn
                                @click="closeModal"
                                class="ma-2"
                                :disabled="loading"
                            >
                                Chiudi
                            </v-btn>
                            <v-btn
                                @click.prevent="destroyTemplate"
                                color="red"
                                dark
                                class="ma-2"
                                :loading="loading"
                            >
                                Elimina
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>

export default {
    name: "delete-document-template",
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        procedureTemplateData: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async destroyTemplate() {
            try {
                this.loading = true
                await this.$api.destroyProcedureTemplate(this.procedureTemplateData.id)
                await this.$store.dispatch('set_alert', {msg: "Modello eliminato con successo", type: 'success'})
                this.$emit('reload-all-data')
                this.$emit('go-to-list')
                this.closeModal()
            } catch (e) {
                this.loading = false
                await this.$store.dispatch('set_alert', {msg: "error", type: 'error'})
            }
        },
        closeModal() {
            this.$emit('close');
            this.loading = false
        }
    },
}
</script>

<style scoped>

</style>
