<template>
    <v-menu
        bottom
        left
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item
                @click="editModalClick"
            >
                <v-list-item-title>
                    <v-icon
                        left
                        small
                    >
                        mdi-pencil
                    </v-icon>
                    Modifica
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                @click="deleteModalClick"
            >
                <v-list-item-title>
                    <v-icon
                        left
                        small
                    >
                        mdi-delete
                    </v-icon>
                    Elimina
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    name: "procedure-template-menu",
    methods: {
        editModalClick(){
            this.$emit('editTemplate')
        },
        deleteModalClick(){
            this.$emit('deleteTemplate')
        },
    }
}
</script>