import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Modifica Modello Pratica")])]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveProcedureTemplate.apply(null, arguments)}}},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Titolo","outlined":"","required":"","dense":"","rules":[_vm.formRules.required]},model:{value:(_vm.localProcedureTemplateData.title),callback:function ($$v) {_vm.$set(_vm.localProcedureTemplateData, "title", $$v)},expression:"localProcedureTemplateData.title"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":"Descrizione","rows":"3","dense":"","outlined":""},model:{value:(_vm.localProcedureTemplateData.description),callback:function ($$v) {_vm.$set(_vm.localProcedureTemplateData, "description", $$v)},expression:"localProcedureTemplateData.description"}})],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.saveProcedureTemplate.apply(null, arguments)}}},[_vm._v(" Salva ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }