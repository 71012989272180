<template>
    <v-card
        class="pa-2"
    >
        <v-card-title
          v-if="!noTitleBlock"
          class="subheading font-weight-bold info--text"
        >
            <span>{{ procedureTemplateObj.title }}</span>
            <v-spacer></v-spacer>
            <procedure-template-menu
                v-if="loggedUser.role === 'professional'"
                @editTemplate="isShow.edit=true"
                @deleteTemplate="isShow.delete=true"
            />
        </v-card-title>
        <v-card-subtitle
            v-if="!noSubtitleBlock"
        >
            {{ procedureTemplateObj.reference }}
        </v-card-subtitle>
        <v-divider
            v-if="!noSubtitleBlock && !noTitleBlock"
        />
        <v-card-text>
            <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="9"
                >
                    <div>
                        {{ procedureTemplateObj.createdAtFormatted }}
                    </div>
                    <div>
                        {{ procedureTemplateObj.description }}
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    class="text-right"
                >
                    <span
                        v-for="(user,uIdx) in procedureTemplateObj.users"
                        :key="'B'+uIdx"
                        class="px-1"
                    >
                        <procedure-users-avatar
                            :user="user"
                        ></procedure-users-avatar>
                    </span>
                    <span
                        v-for="(company,uIdx) in procedureTemplateObj.companies"
                        :key="'C'+uIdx"
                        class="px-1"
                    >
                        <procedure-companies-avatar
                            :company="company"
                        ></procedure-companies-avatar>
                    </span>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="9"
                    class="d-flex align-center"
                >
                  <v-row
                    class="match-height"
                  >
                    <v-col
                      cols="12"
                      sm="12"
                      md="2"
                    >
                      <generic-info-avatar
                        :title="documentsCounterLabel"
                        :total="procedureTemplateObj.documents_count"
                        color="success"
                        icon="mdi-file-document"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    class="d-flex justify-end align-end"
                >
                    <v-btn
                        v-if="procedureEnterBtn"
                        small
                        color="primary"
                        class="mt-3 hidden-xs-only"
                        :to="{name:'procedureTemplateView',params:{id:procedureTemplateObj.id}}"
                    >
                        Entra
                    </v-btn>
                    <v-btn
                        v-if="procedureEnterBtn"
                        small
                        color="primary"
                        class="mt-3 hidden-sm-and-up"
                        :to="{name:'procedureTemplateView',params:{id:procedureTemplateObj.id}}"
                        block
                    >
                        Entra
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
        <edit-procedure-template
          :is-show="isShow.edit"
          :procedureTemplateData="procedureTemplateObj"
          @reload-data="reloadData"
          @close="isShow.edit=false"
        />
        <delete-procedure-template
          :is-show="isShow.delete"
          @close="isShow.delete=false"
          :procedureTemplateData="procedureTemplateObj"
          @reload-data="reloadData"
          @reload-all-data="reloadAllData"
          @go-to-list="goToList"
        />
    </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import ProcedureTemplateMenu from "@/components/menus/procedure-template-menu";

import EditProcedureTemplate from "@/components/modals/edit-procedure-template";
import DeleteProcedureTemplate from "@/components/modals/delete-procedure-template";

import ProcedureUsersAvatar from "@/components/procedure/procedure-users-avatar";
import ProcedureCompaniesAvatar from "@/components/procedure/procedure-companies-avatar";
import CounterChip from "@/components/chips/counter-chip";
import GenericInfoAvatar from "@/components/avatars/generic-info-avatar.vue";

export default {
    name: "document-card",
    components: {
      GenericInfoAvatar,
        ProcedureTemplateMenu,

        ProcedureUsersAvatar,
        ProcedureCompaniesAvatar,
        CounterChip,

        EditProcedureTemplate,
        DeleteProcedureTemplate,
    },
    props: {
        procedureTemplateObj: {
            type: Object,
            required: true
        },
        procedureEnterBtn: {
            type: Boolean,
            default: true
        },
        redirectToDetail: {
            type: Boolean,
            default: false
        },
        noTitleBlock: {
          type: Boolean,
          default: false,
        },
        noSubtitleBlock: {
          type: Boolean,
          default: false,
        },
        headerSmall: {
          type: Boolean,
          default: false,
        },
    },
    data() {
        return {
            isShow: {
                edit: false,
                delete: false,
            },
            mapExpirationChip: {
                'expired' : 'Scaduta'
            }
        }
    },
    computed: {
        ...mapGetters(['loggedUser']),
        documentsCounterLabel() {
            return 'Document' + (this.procedureTemplateObj.documents_count === 0 || this.procedureTemplateObj.documents_count > 1 ? 'i' : 'o')
        },
        surveysCounterLabel() {
            return 'Questionar' + (this.procedureTemplateObj.surveys_count === 0 || this.procedureTemplateObj.surveys_count > 1 ? 'i' : 'io')
        }
    },
    methods: {
        async reloadData(){
            await this.$store.dispatch('getProcedureTemplate',this.procedureTemplateObj.id)
        },
        async reloadAllData(){
            await this.$store.dispatch('getProcedureTemplates')
        },
        goToList(){
            this.$router.push({name:'procedureTemplates'})
        }
    },

}
</script>
